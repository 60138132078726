import React from 'react';

import css from './TransactionPanel.css';
import Button from '../Button/Button';
import { ensureUser } from '../../util/data';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const MeetingLinkSeaction = props => {
  const { currentTransaction } = props;

  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);

  const custPart = currentCustomer && currentCustomer.id && currentCustomer.id.uuid.slice(7);
  const provPart = currentProvider && currentProvider.id && currentProvider.id.uuid.slice(7);
  const link = custPart + provPart;

  return (
    <Button className={css.meetButton}>
      <a
        className={css.meetLink}
        rel="noopener noreferrer"
        target="_blank"
        href={`https://meet.polis.ac/${link}`}
      >
        Link To Your Meeting
      </a>
    </Button>
  );
};

export default MeetingLinkSeaction;
