import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
// import { twitterPageURL } from '../../util/urlHelpers';
// import config from '../../config';
import {
  // IconSocialMediaFacebook,
  // IconSocialMediaInstagram,
  // IconSocialMediaTwitter,
  // ExternalLink,
  NamedLink,
} from '../../components';
import image from '../../assets/Polis_Logo.png';
import css from './Footer.css';

// const renderSocialMediaLinks = intl => {
//   const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
//   const siteTwitterPage = twitterPageURL(siteTwitterHandle);

//   const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
//   const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
//   const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

//   const fbLink = siteFacebookPage ? (
//     <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
//       <IconSocialMediaFacebook />
//     </ExternalLink>
//   ) : null;

//   const twitterLink = siteTwitterPage ? (
//     <ExternalLink
//       key="linkToTwitter"
//       href={siteTwitterPage}
//       className={css.icon}
//       title={goToTwitter}
//     >
//       <IconSocialMediaTwitter />
//     </ExternalLink>
//   ) : null;

//   const instragramLink = siteInstagramPage ? (
//     <ExternalLink
//       key="linkToInstagram"
//       href={siteInstagramPage}
//       className={css.icon}
//       title={goToInsta}
//     >
//       <IconSocialMediaInstagram />
//     </ExternalLink>
//   ) : null;
//   return [fbLink, twitterLink, instragramLink].filter(v => v != null);
// };

const Footer = props => {
  const { rootClassName, className, intl } = props;
  // const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          {/* <div className={css.someLiksMobile}>{socialMediaLinks}</div> */}
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink className={css.logoLink} name="LandingPage">
                <img
                  className={css.logo}
                  src={image}
                  alt={intl.formatMessage({ id: 'Footer.logo' })}
                />
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
                <p className={css.organizationCopyright}>
                  {/* <NamedLink name="LandingPage" className={css.copyrightLink}> */}
                    <FormattedMessage id="Footer.copyright" />
                  {/* </NamedLink> */}
                </p>
              </div>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <a href="https://polis.ac/about" className={css.link}>
                    <FormattedMessage id="Footer.toAbout" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="https://polis.ac/what-we-do" className={css.link}>
                    <FormattedMessage id="Footer.toWhatWeDo" />
                  </a>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <a href="https://polis.ac/resources" className={css.link}>
                    <FormattedMessage id="Footer.toResources" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="https://polis.ac/faq" className={css.link}>
                    <FormattedMessage id="Footer.toFAQ" />
                  </a>
                </li>
              </ul>
            </div>            
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <a href="https://polis.ac/blog" className={css.link}>
                    <FormattedMessage id="Footer.toBlog" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="https://polis.ac/educators" className={css.link}>
                    <FormattedMessage id="Footer.toJoinAsEducator" />
                  </a>
                </li>
              </ul>
            </div>            
            <div className={css.searchesExtra}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <a href="https://polis.ac/people" className={css.link}>
                    <FormattedMessage id="Footer.toOurTeam" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="https://polis.ac/contact-us" className={css.link}>
                    <FormattedMessage id="Footer.toContactUs" />
                  </a>
                </li>
              </ul>
            </div>
            <div className={css.extraLinks}>
              {/* <div className={css.someLinks}>{socialMediaLinks}</div> */}
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                  <a href="https://polis.ac/terms-of-service" className={css.link}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </a>
                  </li>
                  <li>
                  <a href="https://polis.ac/privacy" className={css.link}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </a>
                  </li>
                  <li>
                  <a href="https://polis.ac/cancellation" className={css.link}>
                      <FormattedMessage id="Footer.cancellationPolicy" />
                    </a>
                  </li>                  
                </ul>
              </div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
            <ul className={css.tosAndPrivacy}>
                  <li>
                  <a href="https://polis.ac/terms-of-service" className={css.link}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </a>
                  </li>
                  <li>
                  <a href="https://polis.ac/privacy" className={css.link}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </a>
                  </li>
                  <li>
                  <a href="https://polis.ac/cancellation" className={css.link}>
                      <FormattedMessage id="Footer.cancellationPolicy" />
                    </a>
                  </li>                  
                </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
